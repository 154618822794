<template>
  <div class='row'>
    <div class="product-grid-container__counter">
      <!-- Сейчас всегда показывается N из N потому что для фильтра пагинации нет. -->
      Показано {{currentItemsNumber || products.length }} из {{totalItems || products.length}}
    </div>

    <div
      v-for="product in products"
      :key="product.id"
      :class="['product-grid__item', 'col-' + colSm, 'col-md-' + colMd, 'col-xl-' + colXl]"
    >
      <article class="product-item">
        <a :href="product.url">
          <div class="product-item__image-overlay" />
          <div class="product-item__image">
            <img :src="product.imageUrl" loading="lazy" />
          </div>

          <div class="product-item__desc">
            <div class="product-item__price money">
              <span v-if="product.oldPrice !== null" class='crossed'>
                {{ fPrice(product.oldPrice) }} ₽
              </span>
              <span>
                {{ fPrice(product.price) }} ₽
              </span>
            </div>

            <div class="product-item__name">
              {{ product.name }}
            </div>
          </div>
        </a>
      </article>
    </div>
  </div>
</template>

<script>
import formatPrice from '../../utilities/format-price';

export default {
  name: 'ProductGrid',
  props: {
    colMd:  { type: Number, default: 4},
    colSm:  { type: Number, default: 6},
    colXl:  { type: Number, default: 3},
    currentItemsNumber: { type: Number, default: null},
    products: { type: Array, default: () => { return [] } },
    totalItems: { type: Number, default: null},
  },
  methods: {
    fPrice: formatPrice,
  },
};

</script>
