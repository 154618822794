/* eslint no-console: 0 */

<template>
  <li class="mini-cart__product">
    <a
      class="remove-from-cart remove"
      @click="destroyLineItem"
    >
      <i class="dl-icon-close" />
    </a>

    <div class="mini-cart__product__image">
      <img :src="product.imageUrl">
    </div>

    <div class="mini-cart__product__content">
      <a
        class="mini-cart__product__title"
        :href="product.defaultUrl"
      >
        {{ product.name }}
      </a>

      <span v-show="!preorder()">{{ product.price }} ₽</span>

      <template v-if="preorder()">
        <b>
          Предзаказ
        </b>
      </template>


      <template v-if="hasVariant()">
        <div
          v-if="hasVariant()"
          class="mini-cart__product__option"
        >
          {{ variantName() }}
        </div>
      </template>
    </div>
  </li>
</template>

<script>
export default {
  props: {
    id: String,
    product: Object,
    selectedVariant: Object
  },
  methods: {
    destroyLineItem: function() { this.$emit('destroy-line-item', this.id); },

    variantName: function() {
      const color = this.selectedVariant.color?.name || '';
      const size = this.selectedVariant.option.value || '';
      const cupSize = this.selectedVariant.cupSize || '';
      let result = '';

      if (size != '') {
        result = `${this.selectedVariant.option.shortName}: ${size}`;
      }

      result += cupSize;

      if (color != '') {
        result += ` ${color}`;
      }

      return result;
    },

    hasVariant: function() {
      return this.selectedVariant !== null;
    },

    preorder: function() { return (this.product.status == 'soon_in_stock'); },
  }
};
</script>
