<template>
  <div>
    <GlobalOverlay :menu-id='menuId' :is-open="isVisible" @toggle='toggle'>
    </GlobalOverlay>

    <aside :class="{open: isVisible, right: onRight}" :id="menuId" class='sidemenu'>
      <slot></slot>
    </aside>
  </div>
</template>

<script>
import GlobalOverlay from "../global_overlay.vue";

export default {
  name: 'SidemenuMenu',
  components: {
    GlobalOverlay,
  },
  props: {
    menuId: {
      type: String,
      required: true
    },
    isVisible: Boolean,
    onRight: false,
  },
  methods: {
    toggle() {
      this.$emit('toggle-sidemenu', this.menuId);
    }
  },
};
</script>
