<template>
  <div
    class="ai-global-overlay"
    :class="{'overlay-open': isOpen, 'scroll-y-disabled': isOpen}"
    @click='toggle'
  >
  </div>
</template>

<script>
export default {
  name: 'GlobalOverlay',
  props: {
    isOpen: Boolean,
    menuId: {
      type: String,
      required: true
    },
  },
  methods: {
    toggle() {
      this.$emit('toggle', this.menuId);
    }
  },
};
</script>
