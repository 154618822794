<template>
  <span @click="toggleMenu">
    <slot></slot>
    {{title}}
  </span>
</template>

<script>
export default {
  name: 'SidemenuButton',
  props: {
    menuId: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: false
    }
  },
  methods: {
    toggleMenu() {
      this.$emit('toggle-sidemenu', this.menuId);
    }
  }
};
</script>
