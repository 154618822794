// mutates the object by removing all keys that are in [undefined, []]
export default function cleanObject(obj) {
  for (let key in obj) {
    if (obj[key] === undefined || isEmptyArray(obj)) {
      delete obj[key];
    }
  }
  return obj;

}

function isEmptyArray(obj) {
  return Array.isArray(obj) && obj.length === 0
}
