/* eslint no-console: 0 */

<template>
  <a
    class="mini-cart-btn toolbar-btn"
    @click="toggleCart"
  >
    <i class="dl-icon-cart4" />
    <sup
      v-if="itemsCount > 0"
      class="mini-cart-count"
    > {{ itemsCount }} </sup>
  </a>
</template>

<script>
export default {
  props: {
    itemsCount: Number
  },
  methods: {
    toggleCart: function() { this.$emit('toggle-sidemenu', 'cart'); }
  }
};
</script>
