<template>
  <section class='product-filter'>
    <div class='product-filter__container'>

      <div class='product-filter__head'>
        <b>Фильтры</b>
        <a @click='toggleOpen'><i class="dl-icon-close"> </i> </a>
      </div>

      <div class="product-filter__selected">
        <template v-for="type in filterCategories()">
          <div class="selected-item" v-for="item in selectedItems()[type]">
            <span class='item-name'>{{item.name}}</span>
            <a class='item-remove' @click="deselect(item.id, type)"><i class="dl-icon-close"></i></a>
          </div>
        </template>
      </div>

      <template v-if='selectedFiltersAmount() > 0'>
        <div class='product-filter__reset' @click='resetFilters()'>
          Очистить фильтры
        </div>
      </template>

      <div class="product-filter__body">
        <Accordion title="Цвет" class='product-filter__body__accordion'>
          <div class="item-group item-group--with-buttons colors">
            <div class="item form-check" v-for="color in filterOptions.colors" :key=color.id>
              <input class='form-check-input' type="checkbox" v-model=selected.colors :id=color.id :value=color.id>
              <label :for=color.id class='form-check-label'>
                {{ color.name }}
              </label>
            </div>
          </div>
        </Accordion>

        <template v-for="group in optionsByGroup()">
          <Accordion :title="group[0].optionName" class='product-filter__body__accordion'>
            <div class="item-group item-group--with-buttons options">
              <div class="item" v-for="option in group" :key=option.id>
                <input class='form-check-input' type="checkbox" v-model=selected.options :id=option.id :value=option.id>
                <label :for=option.id class='form-check-label'>
                  {{ option.name }}
                </label>
              </div>
            </div>
          </Accordion>
        </template>

        <Accordion title="Производитель"class='product-filter__body__accordion'>
          <div class="item-group item-group--with-buttons manufacturers">
            <div class="item" v-for="manufacturer in filterOptions.manufacturers" :key=manufacturer.id>
              <input class='form-check-input' type="checkbox" v-model=selected.manufacturers :id=manufacturer.id :value=manufacturer.id>
              <label :for=manufacturer.id class='form-check-label'>
                {{ manufacturer.name }}
              </label>
            </div>
          </div>
        </Accordion>
        <div class="item-group price">
          <label for=price class='form-check-label'>Цена, ₽</label>
            <div class="flex-group">
              <div class="input-group">
                <label> От </label>
                <input v-model.number=selected.minPrice min=0>

              </div>
              <div class="input-group">
                <label> До </label>
                <input v-model.number=selected.maxPrice>
              </div>
            </div>
        </div>
      </div>

    </div>

    <div class='product-filter__footer-wrapper'>
      <div class='product-filter__footer'>
        <a @click='submitFilters'>Показать</a>
      </div>
    </div>

  </section>
</template>

<script>
import Api from "../../api";
import Accordion from "../accordion.vue";

const OPTIONS = "options"
const MANUFACTURERS = "manufacturers"
const COLORS = "colors"

export default {
  props: {
    categoryId: String,
    filterOptions: {
      type: Object,
      default: () => ({
        manufacturers: [],
        options: [],
        colors: [],
        minPrice: 0,
        maxPrice: 0,
      }),
    },
  },
  components: {
    Accordion,
  },
  data: () => {
    return {
      selected: {
        manufacturers: [],
        options: [],
        colors: [],
        minPrice: 0,
        maxPrice: 0,
      },
    };
  },
  created: function() {
    this.selected.minPrice = this.filterOptions.minPrice
    this.selected.maxPrice = this.filterOptions.maxPrice
  },

  methods: {
    toggleOpen: function() { this.$emit('toggle-sidemenu', 'filter'); },
    filterCategories: function() { return [OPTIONS, MANUFACTURERS, COLORS] },

    submitFilters: function() {
      const maxPrice = (this.selected.maxPrice == this.filterOptions.maxPrice) ? undefined : this.selected.maxPrice
      const minPrice = (this.selected.minPrice == this.filterOptions.minPrice) ? undefined : this.selected.minPrice
      const params = {
        product_category_id: this.categoryId,
        manufacturer_ids: this.selected.manufacturers,
        option_ids: this.selected.options,
        color_ids: this.selected.colors,
        min_price: minPrice,
        max_price: maxPrice,
      };
      this.$emit('apply-filters', params);
    },

    selectedItems: function() {
      let itemsByGroup = {};

      this.filterCategories().forEach((category) => {
        let item = {};
        itemsByGroup[category] = this.selected[category].map((selectedItem) => {
          return this.filterOptions[category].find((i) => i.id == selectedItem)
        });
        return item;
      });
      return itemsByGroup
    },

    selectedFiltersAmount: function() {
      return this.filterCategories().reduce((acc, cat) => {
        return acc + this.selected[cat].length
      }, 0);
    },

    resetFilters: function() {
      window.location = window.location.pathname;
    },

    deselect: function(id, type) {
      let index = this.selected[type].indexOf(id);
      if (index !== -1) {
        this.selected[type].splice(index, 1);
      }
    },

    optionsByGroup: function() {
      if (this.filterOptions.options === undefined) {
        return
      }
      return this.filterOptions.options.reduce((result, currentItem) => {
        (result[currentItem['optionName']] = result[currentItem['optionName']] || []).push(currentItem);
        return result;
      }, {});
    },
  }
};

</script>
