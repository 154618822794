import axios from 'axios';

document.addEventListener('DOMContentLoaded', () => {
  let token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
  axios.defaults.headers.common['X-CSRF-Token'] = token;
  axios.defaults.headers.common['Accept'] = 'application/json';
});

const Api = {
  addToCart: function(productId, productVariantId) {
    const params = {
      'product_id': productId,
      'product_variant_id': productVariantId
    };
    return axios.post('/api/cart/line_items', params);
  },

  getFilterOptions: function(params) {
    return axios.get(`/api/catalog/product_filter/options`, { params: params })
  },

  applyFilters: function(params) {
    return axios.get(`/api/catalog/product_filter`, { params: params })
  },

  getCart: function() {
    return axios.get('/api/cart');
  },

  getPrice: function(params) {
    return axios.get('/api/price_calculator', {params});
  },

  removeLineItem: function(id) {
    return axios.delete(`/api/cart/line_items/${id}`);
  },

  createOrder: function(orderParams) {
    return axios.post('/api/orders', orderParams);
  },

  findPromocode: function(promocode) {
    return axios.get('/api/promocode', promocode);
  },
};

export default Api;
