/* eslint no-console: 0 */
<template>
  <div>
    <div
      v-if="hasVariants()"
      class="product-card__options"
    >
      <template v-if="hasOptionVariants()">
        <div class="options-group">
          <div class="options__header">
            <span>Выберите {{ variantOptionName() }}:</span>
            <a
              v-if="hasSizeChart"
              class="size-chart"
              href="#size-chart"
              data-bs-toggle="modal"
              data-bs-target="#size-chart"
            >
              Таблица размеров <span> →</span> 
            </a>
          </div>

          <div
            v-for="variant in variantsByColor(selectedColorId)"
            :key="variant.id"
            class="options__value"
            :class="variant.quantity == 0 ? 'disabled' : ''"
          >
            <label :for="variant.id">
              <input
                :id="variant.id"
                v-model="selectedVariantId"
                type="radio"
                :name="variant.id"
                :value="variant.id"
                :disabled="variant.quantity == 0"
              >
              <span>{{ variantOptionValueName(variant) }}</span>
            </label>
          </div>
        </div>
      </template>

      <template v-if="hasColorVariants()">
        <div class="options-group">
          <div class="options__header">
            <span>Выберите цвет:</span>
          </div>

          <div
            v-for="color in variantColors()"
            :key="color.id"
            class="options__value"
          >
            <label :for="color.id">
              <input
                :id="color.id"
                v-model="selectedColorId"
                type="radio"
                name="color.name"
                :value="color.id"
              >
              <span>{{ color.name }}</span>
            </label>
          </div>
        </div>
      </template>
    </div>

    <div class="product-card__buy-button mb--30 mb--md--10">
      <template v-if="productInStock() || productSoonInStock()">
        <button
          class="btn btn-semi-large btn-large"
          :disabled="loadingState"
          @click="addToCart()"
        >
          <template v-if="productInStock()">
            Добавить в корзину
          </template>
          <template v-if="productSoonInStock()">
            Предзаказ
          </template>

          <div
            v-if="loadingState"
            class="btn-spinner spinner-border"
          >
            <span class="sr-only" />
          </div>
        </button>
      </template>

      <template v-else>
        <a
          class="btn btn-disabled"
          disabled="true"
        > Нет в наличии</a>
        <p class="number">
          Наш менеджер расскажет вам о дате поступления этого товара.
          Для информации или предзаказа воспользуйтесь формой обратной связи
        </p>
      </template>

      <template v-if="hasError()">
        <div>{{ error }}</div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    'product': Object,
    'loadingState': Boolean,
    'error': String
  },

  data: function() {
    let firstVariant;
    if (this.product.variants !== undefined) {
      firstVariant = this.product.variants.find((e) => { return (e.quantity != 0); });
    }

    return {
      selectedVariantId: firstVariant ? firstVariant.id : null,
      selectedColorId: firstVariant ? firstVariant.color?.id : null
    };
  },

  watch: {
    selectedColorId: function(colorId) {
      this.selectedVariantId = this.variantsByColor(colorId)[0].id;
    }
  },

  methods: {
    hasSizeChart: function() { return !(document.getElementById('size-chart' === null)); },
    productInStock: function() { return (this.product.status == 'in_stock'); },
    productSoonInStock: function() { return (this.product.status == 'soon_in_stock'); },

    addToCart: function() {
      const params = {
        productId: this.product.id,
        variantId: this.selectedVariantId
      };
      this.$emit('add-to-cart', params);
    },

    variantColors: function() {
      let uniqColors = [];

      let tempC = {};
      this.product.variants.forEach(variant => {
        if (!variant.color) { return }
        if (!tempC[variant.color.id]) {
          tempC[variant.color?.id] = true;
          uniqColors.push(variant.color);
        }
      })

      return uniqColors;
    },

    variantOptions: function() {
      return this.product.variants.filter((variant) => {
        return ((variant.option && variant.option.value !== undefined) ||
        variant.cupSize && variant.cupSize !== undefined);
      });
    },

    variantsByColor: function(colorId) {
      return this.product.variants.filter( (variant) => {
        return variant.color?.id == colorId;
      });
    },

    hasVariants: function() {
      return this.product.variants !== undefined && this.product.variants.length > 0;
    },

    hasColorVariants: function() {
      return this.variantColors().length > 0;
    },

    hasOptionVariants: function() {
      return this.variantOptions().length > 0;
    },

    variantOptionValueName: function(variant) {
      const option = variant.option.value || '';
      const cupSize = variant.cupSize || '';
      return option + cupSize;
    },

    variantOptionName: function() {
      let name = this.product.variants[0].option.name;
      if (name === undefined) { name = 'размер чашечки'; }
      name = name && name[0].toLowerCase() + name.slice(1) || name; // lower first

      return name;
    },

    hasError: function() {
      return this.error !== undefined;
    }
  },
};
</script>

