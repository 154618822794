<template>
  <div class="product-grid" v-if='isInitialized()'>
    <div class="row">
      <ProductGrid :colMd='colMd' :colSm='colSm' :colXl='colXl'
                   :currentItemsNumber='currentItemsNumber'
                   :products='products'
                   :totalItems='totalItems'
                   >
      </ProductGrid>

      <div class="row" v-if="products.length == 0">
        <h4>К  сожалению, товаров с заданными фильтрами не найдено. Попробуйте выбрать другие параметры фильтра.
        </h4>
      </div>
    </div>
  </div>
</template>

<script>
import ProductGrid from "./product_grid.vue";

export default {
  props: ['colSm', 'colMd', 'colXl', 'currentItemsNumber', 'products', 'totalItems'],
  components: {
    ProductGrid
  },
  methods: {
    isInitialized: function() {
      return (this.totalItems !== null)
    },
  }
};

</script>
