<template>
  <span @click="toggleOpen">Фильтры</span>
</template>

<script>
export default {
  methods: {
    toggleOpen: function() { this.$emit('toggle-sidemenu'); },
  },
};

</script>
