<template>
  <div>
    <div class="accordion-item">
      <div
        @click="toggle()"
        class="accordion-title"
        :aria-expanded="open"
        role="button"
        tabindex="0"
      >
        {{ title }}
        <span class="accordion-icon" :class="{ 'open': open }">&#9662;</span>
      </div>
      <div v-if="open" class="accordion-content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    }
  },
  data: () => {
    return {
      open: false,
    }
  },
  methods: {
    toggle() {
      this.open = !this.open
      this.$emit('toggled', this.open);
    }
  }
};
</script>

<style scoped>
.accordion-item {
  border: 1px solid #e0e0e0;
}

.accordion-title {
  cursor: pointer;
  padding: 10px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accordion-icon {
  transition: 0.2s;
  display: inline-block;
  transform: rotate(180deg);
}

.accordion-icon.open {
  transform: rotate(0);
}

.accordion-content {
  padding: 10px;
  overflow: hidden;
}
</style>

