/* eslint no-console: 0 */
<template>
  <div id="order-form">
    <form
      id="new_order"
      accept-charset="UTF-8"
      class="form-horizontal"
      @submit="submitOrder"
    >
      <div class="row pb--20 pb-md--40 pb-sm--20">
        <div class="col-lg-7 mb-md--40">
          <div
            v-if="withErrors()"
            id="errors"
            class="errors alert alert-danger"
          >
            <div class="alert-heading">
              <h5>Во время заполнения формы были допущены ошибки</h5>
            </div>

            <ul class="alert-body">
              <li
                v-for="error in order.errors"
                :key="error"
              >
                {{ error }}
              </li>
            </ul>
          </div>

          <div class="mb--10" />
          <div class="checkout-title">
            <h2>Адрес доставки</h2>
          </div>

          <div class="checkout-form">

            <div class="form-row mb--30">
              <div class="form__group col-12">
                <label
                  for="order_city"
                  class="form__label form__label--2" > Город </label>

                  <select @change="cityChanged" v-model="order.city" class="form__input form__input--2" name="order_city" id="order_city">
                  <option
                    v-for="(city, index) in courierCities"
                    :key="city"
                    :value="city"
                    class="form__input form__input--2"
                  >
                  {{city}}
                  </option>
                </select>
              </div>
            </div>

            <div class="form-row mb--30">
              <div class="form__group col-12">
                <label
                  for="order_delivery_kind"
                  class="form__label form__label--2"
                >Способ доставки</label>
                <select
                  id="order_delivery_kind"
                  v-model="order.deliveryKind"
                  class="form__input form__input--2"
                  @change="deliveryKindChanged"
                >
                  <option
                    v-for="(dk, index) in deliveryOptionsFiltered()"
                    :key="dk.id"
                    :value="dk.id"
                    class="form__input form__input--2"
                  >
                    {{ dk.text }}
                  </option>
                </select>

                <div class="d-md-none delivery-tip">
                  <a
                    href="/delivery"
                    style="text-decoration: underline"
                  > Условия доставки </a>
                </div>
              </div>
            </div>

            <div class="form-row row mb--30">
              <div class="form__group col-12 col-lg-6 mb-sm--30">
                <label
                  for="order_name"
                  class="form__label form__label--2"
                > Ваше имя
                  <span class="required">*</span>
                </label>
                <input
                  id="order_name"
                  v-model="name"
                  placehodler="Ваше имя"
                  type="text"
                  class="form__input form__input--2"
                  required
                >
              </div>

              <div class="form__group col-12 col-lg-6">
                <label
                  for="order_phone"
                  class="form__label form__label--2"
                > Номер телефона
                  <span class="required">*
                  </span>
                </label>
                <input
                  id="order_phone"
                  v-model="phone"
                  placeholder="+7 (___) ___-____"
                  type="tel"
                  class="form__input form__input--2"
                  required
                >
              </div>
            </div>

            <div class="form-row mb--30">
              <div class="form__group col-12">
                <label
                  for="order_address"
                  class="form__label form__label--2"
                > Адрес
                  <span class="required">*</span>
                </label>
                <input
                  id="order_address"
                  v-model="address"
                  placeholder="Улица и дом"
                  type="text"
                  class="form__input form__input--2"
                >
              </div>
            </div>

            <div class="form-row mb--30">
              <div class="form__group col-12">
                <label
                  for="order_email"
                  class="form__label form__label--2"
                >Email
                </label>
                <input
                  id="order_email"
                  v-model="email"
                  type="text"
                  class="form__input form__input--2"
                >
              </div>
            </div>


            <div class="form-row">
              <div class="form__group col-12">
                <label
                  for="order_comment"
                  class="form__label form__label--2"
                >Комментарий к заказу</label>
                <textarea
                  id="order_comment"
                  v-model="comment"
                  placeholder="Комментарии к заказу, например желаемое время доставки."
                  class="form__input form__input--2 form__input--textarea comment"
                />
              </div>
            </div>
          </div>
        </div>

        <!-- ORDER REVIEW -->
        <div class="col-xl-4 offset-xl-1 col-lg-5">
          <div class="order-details">
            <div class="mb--10" />
            <div class="checkout-title">
              <h2>Ваш заказ</h2>
            </div>
            <div class="table-content table-responsive mb--30">
              <table class="table order-table order-table-2">
                <thead>
                  <tr>
                    <th>Товар</th>
                    <th class="text-right">
                      Стоимость
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="li in cart.lineItems"
                    :key="li.id"
                  >
                    <!-- <th>{{truncate(li.product.name, 20)}}</th> -->
                    <th>{{ li.product.name }}</th>
                    <td class="text-right">
                      <template v-if="inStock(li)">
                        {{ li.product.price }}
                      </template>
                      <template v-else>
                        Предзаказ
                      </template>
                    </td>
                  </tr>
                </tbody>
              </table>

              <table class="table order-table order-table-2">
                <tr class="shipping">
                  <th>Доставка с примеркой
                    <div class="d-none d-md-block">
                      <a
                        href="/delivery"
                        style="text-decoration: underline;position: absolute"
                      > Условия доставки </a>
                    </div>
                  </th>
                  <td class="text-right">
                    {{ deliveryPrice() }}
                  </td>
                </tr>
                <tr
                  v-if="promocodeApplied()"
                  class="promocode"
                >
                  <th>Промокод</th>
                  <td class="text-right">
                    {{ cart.price.discount }}₽
                  </td>
                </tr>
              </table>

              <table class="table order-table order-table-2">
                <tr class="order-total">
                  <th>Стоимость заказа</th>
                  <td class="text-right">
                    {{ cart.price.totalPrice }}₽
                  </td>
                </tr>
              </table>
            </div>

            <div class="checkout-payment">
              <div class="payment-group mb--10">
                <div class="payment-radio">
                  <input
                    id="cash"
                    checked="checked"
                    name="payment-method"
                    type="radio"
                    value="cash"
                  >
                  <label
                    for="cash"
                    class="payment-label"
                  >Наличными при доставке</label>
                </div>

              </div>
              <div class="mb--20" />
              <div class="payment-group">
                <template v-if="withPreorder()">
                  <p> Ваш заказ содержит товары которые в <b>скором времени появятся</b> в нашем ассортименте. Наш менеджер свяжется с вами и расскажет о ближайщей возможной дате доставки </p>
                </template>

                <p class="mb--15" />
                <button
                  type="submit"
                  name="commit"
                  class="btn btn-fullwidth btn-style-1"
                  value="Сделать заказ"
                  :disabled="loadingState"
                >
                  Сделать заказ
                  <div
                    v-if="loadingState"
                    class="btn-spinner spinner-border"
                  >
                    <span class="sr-only" />
                  </div>
                </button>

                <div class="oferta_notification">
                  <p class="mb--5" />
                  Нажимая на кнопку "Сделать заказ", вы принимаете условия <a href="/about/oferta"><u>Публичной оферты</u></a>
                </div>
              </div>
            </div>
          </div>

          <div class="row pt--80 pt-md--60 pt-sm--40">
            <div class="col-12">
              <div class="user-actions user-actions__coupon">

                <div class="message-box mb--30 mb-sm--20">
                  <p v-if="!promocodeEntered">
                    У вас есть купон? <a
                      href="#coupon_info"
                      class="expand-btn"
                      @click.prevent="togglePromocodeFieldVisible"
                    >Нажмите для добавления</a>
                  </p>
                  <p v-else>
                    Промокод "{{ order.promocode }}" добавлен!
                  </p>
                </div>

                <div
                  id="coupon_info"
                  class="user-actions__form"
                  :class="{ 'hide-in-default': !promocodeFieldVisible}"
                >
                  <form
                    v-if="!promocodeApplied()"
                    class="form"
                    @submit="submitPromocode"
                  >
                    <p>Введите код купона, если имеется:</p>
                    <div class="form__group d-sm-flex">
                      <input
                        id="promocode-input"
                        v-model="order.promocode"
                        placeholder="Введите промокод"
                        type="text"
                        class="form__input form__input--2"
                        :class="(promocodeEntered && !promocodeApplied()) ? 'error' : ''" >
                      <button
                        type="submit"
                        class="btn btn-medium btn-style-1"
                      >
                        Применить
                      </button>
                    </div>
                    <p
                      v-if="promocodeEntered && !promocodeApplied()"
                      color="red"
                    >
                      Промокод не найден!
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  // NOTE: default values are examples, ensure that default values are set in view or in the root component
  props: {
    "cart": {
      type: Object,
      default () {
        return { error: "", lineItems: []};
      }
    },
    "order": {
      type: Object,
      default () {
        return {errors: [], deliveryKind: "pickup", promocodeId: null, city: "Москва"};
      }
    },
    "deliveryOptions": {
      type: Array,
      default () {
        return ["Mосква"];
      }
    },
    "courierCities": {
      type: Array,
      default () {
        return []
      }
    },

    "loadingState": Boolean,
  },
  data: function(){
    return {
      name: "",
      email: "",
      phone: "",
      address: "",
      comment: "",
      promocode: "",
      promocodeEntered: false,
      promocodeFieldVisible: false
    };
  },
  watch: {
    order:{ handler: function() {
      if (this.order.errors.length == 0) { return; }
      document.getElementById("order-form").scrollIntoView();
    }, deep: true}
  },
  mounted() {
    this.$emit("update-price");
  },
  methods: {
    submitOrder: function(e) {
      const orderParams = {
        name: this.name,
        city: this.order.city,
        email: this.email,
        phone: this.phone,
        address: this.address,
        delivery_kind: this.order.deliveryKind,
        comment: this.comment,
        promocode: this.order.promocode,
      };

      this.$emit("submit-order", orderParams);
      e.preventDefault();
    },

    submitPromocode: function(e) {
      e.preventDefault();
      this.$emit("update-price");
      this.promocodeEntered = true;
    },

    deliveryKindChanged: function(e) {
      e.preventDefault();
      this.$emit("update-price");
    },

    cityChanged: function(e) {
      e.preventDefault();
      this.order.deliveryKind = this.deliveryOptionsFiltered()[0].id;
      this.$emit("update-price");
    },

    withErrors: function() { return this.order.errors.length; },

    withPreorder: function() {
      return this.cart.lineItems.some (function(e) {
        return e.product.status == "soon_in_stock";
      });
    },

    deliveryOptionsFiltered: function() {
      return this.deliveryOptions.filter((opt) => {
        if  (!['courier', 'express_courier', 'pickup'].includes(opt.id) ) { return true; }
        return this.courierDeliveryPossible()
      });
    },

    courierDeliveryPossible: function() {
      return this.order.city != "Другой";
    },

    deliveryPrice: function() {
      if (typeof(this.cart.price.deliveryPrice) == 'number') {
        return `${this.cart.price.deliveryPrice}₽`
      }
      return this.cart.price.deliveryPrice
    },

    inStock: function(line_item) { return (line_item.product.status == "in_stock"); },
    promocodeApplied: function() { return this.cart.price.discount > 0;},
    togglePromocodeFieldVisible: function() { this.promocodeFieldVisible = !this.promocodeFieldVisible}
  },
};

</script>

