/* eslint no-console: 0 */

<template>
  <aside
    id="cart"
    class="mini-cart"
    :class="{open: isOpen}"
  >
    <div class="mini-cart-wrapper">
      <a
        class="btn-close js-mini-cart-close"
        @click="toggleOpen"
      >
        <i class="dl-icon-close" />
      </a>

      <div class="mini-cart-inner">
        <span id="mini-cart-message">
          {{ message }}
        </span>

        <div v-if="!isEmpty()">
          <div class="mb--20 mb-lg--10">
            <span class="h3 mini-cart__heading">
              Корзина
            </span>
          </div>


          <div class="mini-cart__content">
            <transition-group
              name="fade"
              tag="ul"
              class="mini-cart__list"
            >
              <line-item
                v-for="lineItem in lineItems"
                :id="lineItem.id"
                :key="lineItem.id"
                :product="lineItem.product"
                :selected-variant="lineItem.productVariant"
                @destroy-line-item="destroyLineItem"
              />
            </transition-group>

            <div class="mini-cart__total">
              <span> Подитог </span>
              <span class="ammount"> {{ price.lineItemsPrice }}₽</span>
            </div>

            <div class="mini-cart__buttons">
              <a
                class="btn btn-fullwidth btn-style-1"
                href="/orders/new"
              >Заказать</a>
            </div>
          </div>
        </div>

        <div class="mini-cart__empty" v-else>
          <span> Корзина пуста </span>
        </div>
      </div>
    </div>
  </aside>
</template>

<script>
export default {
  props: {
    isOpen: Boolean,
    lineItems:  {
      type: Array,
      default () {
        return [];
      }
    },
    price: {
      type: Object,
      default () {
        return {
          deliveryPrice: 0,
          discount: 0,
          lineItemsPrice: 0,
          totalPrice: 0
        };
      }
    }
  },

  data: () => {
    return {
      message: ''
    };
  },

  methods: {
    toggleOpen: function() { this.$emit('toggle-sidemenu', 'cart'); },

    destroyLineItem: function(id) { this.$emit('destroy-line-item', id); },

    isEmpty: function() { return this.lineItems.length == 0; },
  }
};
</script>
